
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
export default {
  setup() {
    const router = useRouter();
    console.log(router);
    const route = useRoute();
    let skin: any = ref("1");
    skin.value = route.query.skin || "1";

    return {
      skin,
    };
  },
};
